<!-- 货币兑换历史记录 -->
<template>
  <div class="CurrencyRecord">
    <el-table v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;">
      <!--   用户类型   -->
      <el-table-column :label="$t('sysUser.userType')" prop="userCode" align="center" />
      <!--   兑换类型   -->
      <el-table-column :label="$t('exchange.type')" align="center">
        <!-- <template slot-scope="{row}">
          {{ row.acType }} -> {{ row.toAcType }}
        </template> -->
        <template slot-scope="{row}">
<!--          <span v-if="row.acType === 'F$'">{{ 'W$' }} -> {{ row.toAcType }}</span>-->
<!--          <span v-else-if="row.toAcType === 'F$'">{{ row.acType }} -> {{ 'W$' }}</span>-->
<!--          <span v-else>{{ row.acType }} -> {{ row.toAcType }}</span>-->
          <span v-if="row.acType === 'F$'">{{ 'F$' }} -> {{ row.toAcType }}</span>
          <span v-else-if="row.toAcType === 'F$'">{{ row.acType }} -> {{ 'F$' }}</span>
          <span v-else>{{ row.acType }} -> {{ row.toAcType }}</span>
        </template>
      </el-table-column>
      <!--   金额   -->
      <el-table-column :label="$t('Account.money')" prop="money" align="center" />
      <!--   汇率   -->
      <el-table-column :label="$t('alCurrency.rate')" prop="rate" align="center" />
      <el-table-column :label="$t('MXN')"  align="center" >
        <template v-slot="{row}">
          <span>{{ Number(row.money) * Number(row.rate) }}</span>
        </template>
      </el-table-column>
      <!--   状态   -->
      <!-- <el-table-column :label="$t('状态')" prop="status" align="center" /> -->
      <!--   创建时间   -->
      <el-table-column :label="$t('alRateAdjust.createTime')" prop="createTime" align="center" />
    </el-table>
    <!--    分页-->
    <pagination v-show="total>0" :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList" />
    <el-button style="float: right; margin-bottom: 10px" type="warning" @click="continueBtn" plain>{{$t('btn.continue')}}</el-button>
  </div>
</template>

<script>
  import { getFiAcEXChange } from "@/api/rus/api"
  import Pagination from '@/components/Pagination'
  export default {
    name: 'CurrencyRecord',
    components: { Pagination },
    data () {
      return {
        listLoading: false,
        list: [],
        listQuery: {
          page: 1,
          limit: 10
        },
        total: 0
      }
    },
    created () {
      this.getList()
    },
    methods: {
      getList () {
        this.listLoading = true
        getFiAcEXChange({ ...this.listQuery }).then((res) => {
          this.list = res.data.records
          this.list.forEach(v=>{
            if(v.acType === 'B$'){
              v.acType = 'MXN'
            }
            if(v.acType === 'FB'){
              v.acType = 'W$'
            }
            if(v.toAcType === 'B$'){
              v.toAcType = 'MXN'
            }
          })
          this.total = +res.data.total
          this.listLoading = false
        }).catch(() => {
          this.listLoading = false
        })
      },
      continueBtn() {
        this.$router.push({ path: 'currency-exchange' })
      }
    }
  }
</script>

<style lang="scss" scoped>
</style>
